import moment from "moment";
import { ref } from "vue";
import { configScheduleOption, Config } from '@/views/agenda-virtual/utils/ConfigScheduleUtils'
import { useStore } from "vuex";

export interface TimesData {
    text: string,
    value: string,
    disabled: boolean
}

export const defaultTimes = () => {

    const store = useStore();

    const times = (
        {
            minTime = '07:00',
            maxTime = '19:00',
            step = 15, 
            showLastTime = false,
            taskDate = moment()
        } = {} // Adicionei um valor padrão vazio para o objeto de parâmetros
        ): TimesData[]  => {
        
        const modeloAgendaSelect = ref(store.getters.modeloAgendaName)
        const configSchedule = ref<Config>(configScheduleOption(modeloAgendaSelect.value))

        const refTrueLastTime = moment(maxTime, "HH:mm");
        let refLastTime = refTrueLastTime.clone();
        if (!showLastTime) {
            refLastTime.subtract(step, "minutes");
        }

        const currentTime = moment()
        let auxDefaultTimes: any = [];
        let auxTime = moment(minTime, "HH:mm");
        auxTime.subtract(step, "minutes");

        while (auxTime.clone().isBefore(refLastTime)) {
            auxTime.add(step, "minutes");
            const nextTime = auxTime.clone();

            if (nextTime.clone().isBefore(refTrueLastTime) || (showLastTime && nextTime.isSame(refTrueLastTime))) {

                let disabled = false
                const now = moment();
                const time = moment(nextTime.format("HH:mm"), "HH:mm");
                const dateToCompare = moment(taskDate)
                if (configSchedule.value?.programBeforeCurrenTime && dateToCompare.isSameOrBefore(now, 'day')) {
                    disabled = !currentTime.isBefore(time);
                }

                auxDefaultTimes.push({
                    text: nextTime.format("HH:mm"),
                    value: nextTime.format("HH:mm"),
                    disabled: disabled
                });
            }
        }

        return auxDefaultTimes;
    }

    return {
        times
    }
}

