export interface LgpdConfig {
  service: {
    placa: boolean;
  };
  programming: {
    placa: boolean;
  };
}
export interface ParceiroConfig {
  exist: boolean;
  logo: string;
}
export interface ProgressBar {
  service: {
    show: boolean,
    legend: boolean
  },
  programming: {
    show: boolean,
    legend: boolean
  },
}
export interface ShowChip {
  service: {
    execute: number[],
    plan: number[]
  }
}
export interface ReceptionConfig {
  colorProgress: string;
  dualReception: boolean;
}

interface LateTime {
  programming: boolean,
  service: boolean
}

export interface Config {
  color: string;
  logoSemTexto: string;
  logoTexto: string;
  parceiro: ParceiroConfig;
  showPrisma: boolean;
  showOs: boolean;
  showModelo: boolean;
  showDataEntrega: boolean;
  showDataRecepcao: boolean;
  clienteAguarda: boolean;
  lgpd: LgpdConfig;
  reception: ReceptionConfig;
  progressBar: ProgressBar;
  showChip: ShowChip;
  programBeforeCurrenTime: boolean;
  lateTime: LateTime;
}

const HYUNDAI_CONFIG: Config = {
  color: 'var(--cor_layout_1)',
  logoSemTexto: './img_marca/hyundai/logo-hyundai-sem-texto.png',
  logoTexto: './img_marca/hyundai/logo-hyundai-texto.png',
  parceiro: {
    exist: true,
    logo: './img_marca/hyundai/logo-shell.png',
  },
  showPrisma: false,
  showOs: false,
  showModelo: false,
  showDataEntrega: true,
  showDataRecepcao: true,
  clienteAguarda: true,
  lgpd: {
    service: {
      placa: true,
    },
    programming: {
      placa: true,
    },
  },
  progressBar: {
    service: {
      show: false,
      legend: false
    },
    programming: {
      show: false,
      legend: false
    },
  },
  showChip: {
    service: { 
      execute: [2], // qual status vai aparecer na parte inicio/fim de 2 - em serviço  // 5 - paralizado
      plan: [1, 5] // qual status vai aparecer na parte  plano
    }
  },
  reception: {
    colorProgress: 'white',
    dualReception: false,
  },
  programBeforeCurrenTime : true,
  lateTime: {
    programming: false,
    service: false
  }
};

const RENAULT_CONFIG: Config = {
  color: 'var(--cor_renault_agenda)',
  logoSemTexto: './img_marca/renault/renault_sem_texto.png',
  logoTexto: './img_marca/renault/logo-renault-texto.png',
  parceiro: {
    exist: false,
    logo: '',
  },
  showPrisma: true,
  showOs: true,
  showModelo: true,
  showDataEntrega: false,
  showDataRecepcao: false,
  clienteAguarda: false,
  lgpd: {
    service: {
      placa: false,
    },
    programming: {
      placa: true,
    },
  },
  progressBar: {
    service: {
      show: true,
      legend: true
    },
    programming: {
      show: true,
      legend: true
    },
  },
  showChip: {
    service: {
      execute: [2, 3, 4, 6], // qual status vai aparecer na parte inicio/fim de 2 - em serviço  // 6 - finalizado // 3 - controle de qualidade
      plan: [1, 5] // qual status vai aparecer na parte plano
    }
  },
  reception: {
    colorProgress: 'black',
    dualReception: true
  },
  programBeforeCurrenTime : true,
  lateTime: {
    programming: true,
    service: true
  }
};

const config = {
  hyundaiV1: HYUNDAI_CONFIG,
  renaultV1: RENAULT_CONFIG,
};

const configScheduleOption = (modelSchedule: string = ''): Config => {
  const formatName = toLowerCaseFirstLetter(modelSchedule);
  return config[formatName];
};

const toLowerCaseFirstLetter = (str: string = ''): string =>
  str.charAt(0).toLowerCase() + str.slice(1);

export { configScheduleOption };
