
import { defineComponent, defineAsyncComponent, ref, onMounted, computed, reactive, toRefs, watch ,shallowRef } from 'vue';
import useEmitter from '@/composables/Emmiter';
import AdicionarOutrosMecanicoModal from "./components/modais/servicos/AdicionarOutrosMecanicoModal.vue";
import AgendamentoModal from  "@/views/agenda-virtual/components/modais/agendamentos/AgendamentoModal.vue";
import AuthService from "@/services/AuthService";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';
import { Modal } from "bootstrap";
import { Actions } from "@/store/enums/StoreEnums";
import '@fullcalendar/core/vdom' // solves problem with Vite
import { generate } from '@/views/agenda-virtual/composables/chipSchedule'


export default defineComponent({
  name: "agendar-agendar",
  components: {
    AdicionarOutrosMecanicoModal,
    AgendamentoModal,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;
    const programaAgenda = userInfo.programaAgenda;

    const concessionariaInfo:any = ref({});
    const refTab:any = ref("");
    const emitter = useEmitter();
    const mecanicos:any = ref([]);
    const consultores:any = ref([]);
    const permissions = {
      showAgenda: [-1, 1, 2, 4, 5],
      showProgramacao: [-1, 1, 2, 4]
    };
    const loadings = reactive({
      loadingGlobal: true,
      loadingBaseAgendamento: true
    });
    const modelosCalendar= reactive({
      modeloAgendamento: "",
      modeloRecepcao: "",
      modeloProgramacao: "",
      modeloServicos: ""
    });
    const initialForm = ref({
      codAgendamento: "",
      detalhes: "",
      cliente: "",
      placa: "",
      taskType: "",
      taskDate: "",
      taskStartHour: "",
      taskEndHour: "",
      taskHourEntrega: "",
      taskHourRecepcao: "",
      formType: "",
      tecnico: "",
      dataAgendamento: "",
      marca: "",
      modelo: "",
      osAberta: "",
    });
    const disableChange = ref(true);
    const modeloAgendaSelect = ref(store.getters.modeloAgendaName)
    const servicosParalizados = ref([])
    const { setModelSchedule } = generate()

    watch(() => store.getters.concessionariaSelectedInfo.codConcessionaria, () => store.dispatch(Actions.SET_BASE_AGENDAMENTOS))

    // LIFECYCLE HOOKS
    onMounted(async () => {
      defineModeloAgenda();

      let initialTab;

      if(route.params.view){
        initialTab = route.params.view;
      } else {
        if(permissions.showProgramacao.includes(codNivel)) {
          initialTab = "programacao"
        } else if (permissions.showAgenda.includes(codNivel)){
          initialTab = "servicos"
        }
      }
      refTab.value = initialTab;

      try{
        if(isScreenLockSupported()){
          const anyNavigator:any = navigator;
          anyNavigator.wakeLock.request('screen');
        }
      } catch (e){
        console.error(e);
      }
      store.dispatch(Actions.START_AGENDA);

      if(store.getters.concessionariaSelectedInfo){
        concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
      }
    });

    emitter.on("agenda-loading", () => {
      loadings.loadingGlobal = true;
      disableChange.value = true;
    });

    emitter.on("end-update-calendar", () => {
      loadings.loadingGlobal = false;
      setTimeout(() => {
        disableChange.value = false;
      }, 5000)
      setUpdateInterval();
    });
    
    // WATCH HOOKS
    store.watch(() => store.getters.modeloAgendaName, () => {
      modeloAgendaSelect.value = store.getters.modeloAgendaName
      if(!store.getters.modeloAgendaName) return 
      defineModeloAgenda();
    });

    store.watch(() => store.getters.mecanicosList, () => {
      mecanicos.value = store.getters.mecanicosList;  
    }, { deep: true });

    store.watch(() => store.getters.consultoresList, () => {
      consultores.value = store.getters.consultoresList;
    }, { deep: true });

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.servicoParalizadoList, () => {
      servicosParalizados.value = store.getters.servicoParalizadoList.map(servico => {
        return {
          ...servico,
          isIconCloserVisible : false
        }
      })
    }, { deep: true });

    // FUNCTIONS
    function defineModeloAgenda(){
      if(store.getters.modeloAgendaName){
        let modeloName;
        if(store.getters.modeloAgendaName == 'HyundaiV1'){
          modeloName = `Modelo${store.getters.modeloAgendaName}`
        }else if(store.getters.modeloAgendaName == 'CampV1'){
          modeloName = `Modelo${store.getters.modeloAgendaName}`
        }else{
          modeloName = 'ModeloHyundaiV1'
        }
        setModelSchedule()
        modelosCalendar.modeloAgendamento = shallowRef(defineAsyncComponent(() => import(`./components/modelos/${modeloName}/${modeloName}Agendamentos.vue`)));
        modelosCalendar.modeloProgramacao = shallowRef(defineAsyncComponent(() => import(`./components/modelos/${modeloName}/${modeloName}Programacao.vue`)));
        modelosCalendar.modeloServicos = shallowRef(defineAsyncComponent(() => import(`./components/modelos/${modeloName}/${modeloName}Servicos.vue`)));
        modelosCalendar.modeloRecepcao = shallowRef(defineAsyncComponent(() => import(`./components/modelos/${modeloName}/${modeloName}Recepcao.vue`)));
      }
    }

    function isScreenLockSupported() {
      return ('wakeLock' in navigator);
    }

    function updateCalendar() {
      emitter.emit("update-calendar");
    }

    function openEventModalMecanico() {
      initialForm.value = {
        codAgendamento: "",
        detalhes: "",
        cliente: "",
        placa: "",
        taskType: "",
        taskDate: "",
        taskStartHour: "",
        taskEndHour: "",
        taskHourEntrega: "",
        taskHourRecepcao: "",
        formType: "",
        tecnico: "",
        dataAgendamento: "",
        marca: "",
        modelo: "",
        osAberta: "",
      };

      const modal = new Modal(document.getElementById("kt_modal_event_mecanico"));
      modal.show();
    };

    function openAgendamentoModal() {
      const modal = new Modal(document.getElementById("kt_modal_select_agendamento"));
      modal.show();
    };

    function goTo(url){
			router.push(url)
		};

    function changeTab()
    {
      setUpdateInterval();
    }

    function setUpdateInterval(){
      emitter.emit("agenda-clear-interval");

      if(refTab.value === "recepcao"){
        emitter.emit("update-agenda-recepcao");
      }

      if(refTab.value === "agendamentos"){
        emitter.emit("update-agenda-agendamentos");
      }

      if(refTab.value === "programacao"){
        emitter.emit("update-agenda-programacao");
      }

      if(refTab.value === "servicos"){
        emitter.emit("update-agenda-servicos");
      }
    }

    const signOut = () => {
      store.dispatch(Actions.LOGOUT);
    };

    return {
      modeloCalendarName: computed(() => store.getters.modeloAgendaName),
      ...toRefs(modelosCalendar),
      ...toRefs(loadings),
      openEventModalMecanico,
      openAgendamentoModal,
      updateCalendar,
      goTo,
      changeTab,
      concessionariaInfo,
      codNivel,
      mecanicos,
      consultores,
      refTab,
      permissions,
      initialForm,
      disableChange,
      programaAgenda,
      modeloAgendaSelect,
      servicosParalizados,
      signOut
    }
  }
});
