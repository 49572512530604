
import FiltroDataModal from "@/views/agenda-virtual/components/modais/agendamentos/components/FiltroDataModal.vue";
import AdicionarServicoModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoModal.vue";
import EditarServicoModal from "@/views/agenda-virtual/components/modais/servicos/EditarServicoModal.vue";
import VisualizarServicoModal from "@/views/agenda-virtual/components/modais/servicos/VisualizarServicoModal.vue";
import AdicionarServicoPassanteModal from "@/views/agenda-virtual/components/modais/servicos/AdicionarServicoPassanteModal.vue";

import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";
import { getBaseAgendamento } from "@/services/AgendaService";
import { Modal } from "bootstrap";
import useAlert from "@/composables/Alert";
import { ContentLoader } from 'vue-content-loader';
import { config } from "@/views/agenda-virtual/ConfigEvents";
import useEmitter from '@/composables/Emmiter';
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { AgendamentoServicosDms} from "@/models/AgendaModel";
import useUtilChanges from '@/composables/UtilChanges';
import StatusChecklist from '@/views/agenda-virtual/components/status/StatusChecklist.vue'

interface extendedProps {
  extendedProps:{    
    duration: number,
    status: number,
    showDuration: boolean,
    aguarda: false,
    hasContent: true,
    hasFooter: true,
    cliente: string,
    consultor: string,
    recepcao: string,
    entrega: string,
    placa: string,
    bgColor: string,
    headerTextColor: string,
    allInfo: any
  },
  title: string
}

interface AgendamentoTratado extends AgendamentoServicosDms {
  data: string,
  consultorTratado: string,
  clienteTratado: string, 
  servicosAgendadosTratado: extendedProps[]  
}

export default defineComponent({
  name: "new-event-modal",
  components: {
    FiltroDataModal,
    AdicionarServicoModal,
    AdicionarServicoPassanteModal,
    VisualizarServicoModal,
    EditarServicoModal,
    ContentLoader,
    StatusChecklist
  },

  emits: ['success'],

  setup(props, { emit }) {
    const store = useStore();
    const eventInfo:any = ref();
    const { showTimeAlert } = useAlert();
    const { cutChassi } = useUtilChanges();
    const agendamentos = ref<AgendamentoTratado[] | []>([]);
    const agendamentosAgendados = ref<AgendamentoTratado[] | []>([]);
    const initialForm = ref();
    const loading:any = ref(true);
    const refModalNew:any = ref(null);
    const refModalNewWithoutRef:any = ref(null);
    const dataMesAtual = moment().startOf("day");
    const dataMesAtualFormatada = dataMesAtual.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de");
    const dataFiltrada:any = ref(dataMesAtualFormatada);
    const emitter = useEmitter();
    const concessionariaInfo:any = ref({});
    const horaAbertura = ref()

    function horariosQuadro(){     
      const concessionaria = store.getters.concessionariaSelectedInfo
      if(concessionaria){
        horaAbertura.value =  moment(concessionaria.horaAbertura, "H").format("HH:mm")       
      }         
    }

    // LifeCycles
    onMounted (() => {
      horariosQuadro() 
      
      refModalNew.value = new Modal(document.getElementById("kt_modal_add_event_agendamento-modal-servico"));
      refModalNewWithoutRef.value = new Modal(document.getElementById("kt_modal_add_without_ref_event_agendamento-modal-passante"));

      if(store.getters.baseAgendamentos > 0){
        getAgendamentos(store.getters.baseAgendamentosList);
      }
    });

    store.watch(()=>store.getters.concessionariasList,()=> {
        if(store.getters.concessionariasList) horariosQuadro()        
      })

    store.watch(() => store.getters.concessionariaSelectedInfo, () => {
      concessionariaInfo.value = store.getters.concessionariaSelectedInfo;
    }, { deep: true });

    store.watch(() => store.getters.baseAgendamentosList, () => {
      const auxMomentDate = moment(lastDateFilter.value, "YYYY-MM-DD HH:mm:ss");

      if(auxMomentDate.isSame(moment(), "day")){
        getAgendamentos(store.getters.baseAgendamentosList);
      }
    }, { deep: true });

    emitter.on("agenda-loading", () => {
      loading.value = true;
    });

    function trataNome(nome){
      if(!nome || nome == "undefined" || nome == undefined){
        return "";
      }
      
      const arrayNomes = nome.split(" ");
      const nomeTratado = `${arrayNomes[0]} ${arrayNomes[arrayNomes.length - 1].substring(0, 1)}.` 

      return nomeTratado;
    }

    const refNow = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const lastDateFilter:any = ref(refNow);

    function openVisualizarServicoModal(info) {
      eventInfo.value = info.extendedProps.allInfo;

      if(eventInfo.value.tempoAdicional){
        const end = moment(eventInfo.value.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
        eventInfo.value.endWithout = end.subtract(eventInfo.value.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
      } else {
        eventInfo.value.endWithout = eventInfo.value.dataInicioServico.replace(" ", "T");
      }
      const modal = new Modal(document.getElementById("kt_modal_event_info_2"));
      modal.show();
    };

    function openEditVisualizarServicoModal(info) {
      eventInfo.value = info.extendedProps.allInfo;

      if(eventInfo.value.tempoAdicional){
        const end = moment(eventInfo.value.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
        eventInfo.value.endWithout = end.subtract(eventInfo.value.tempoAdicional, "minutes").format("YYYY-MM-DD HH:mm:ss");
      } else {
        eventInfo.value.endWithout = eventInfo.value.dataInicioServico.replace(" ", "T");
      }
      const modal = new Modal(document.getElementById("kt_modal_event_edit_info_2"));
      modal.show();
    };

    function openModalServicosAgendados() {
       // Obtenha o elemento do modal
    const modalElement: HTMLElement | null =  document.getElementById("kt_modal_select_agendamento_realizados");

    if (modalElement) {
        modalElement.style.zIndex = "1071"; 
        const modal = new Modal(modalElement);
        modal.show();
    } else {
        console.error("Elemento modal não encontrado.");
    }
    };

    function closeModalServicosAgendados() {
      const modal = document.getElementById("kt_modal_select_agendamento_realizados");
      hideModal(modal);
    };

    async function filtraDados(dataSelecionada){
      const auxDataSelecionada = moment(dataSelecionada, "YYYY-MM-DD HH:mm:ss");
      const diferencaDias = auxDataSelecionada.diff(moment().startOf("day"), 'days');

      lastDateFilter.value = auxDataSelecionada.format("YYYY-MM-DD HH:mm:ss");
      dataFiltrada.value = auxDataSelecionada.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de");

      if(diferencaDias == 0){
        store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        return;
      }

      loading.value = true;
      const response = await getBaseAgendamento(concessionariaInfo.value.codConcessionaria, diferencaDias);
      loading.value = false;
      getAgendamentos(response);

      return response;
    };

    async function emiteSuccess() {
      const auxData = moment(lastDateFilter.value, "YYYY-MM-DD HH:mm:ss");

      
      if(auxData.isSame(moment(), "day")) {
        loading.value = true;
        emitter.emit("update-servicos-calendar", { auxData: auxData, auxArrayAgendamento: [] });
        await store.dispatch(Actions.SET_BASE_AGENDAMENTOS);
        loading.value = false;
      } else {
        const auxAgendamentos = await filtraDados(lastDateFilter.value);
        emitter.emit("update-servicos-calendar", { auxData: auxData, auxArrayAgendamento: auxAgendamentos });
      }      
    };

    function removeAcentos(name, toLowerCase = false) {
      if(!name){
        return "";
      }
      const stringTratado = name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return toLowerCase ? stringTratado.toLowerCase() : stringTratado;
    };

    async function getAgendamentos(refBase){
      try{
        loading.value = true;
        agendamentos.value = [];
        agendamentosAgendados.value = [];

        let baseAgendamentosList  = refBase.filter(agendamento => agendamento.idagendamento && agendamento.placa || agendamento.chassi);

        baseAgendamentosList.forEach(itemAgendamento => {
          const clienteTratado = itemAgendamento.cliente ? trataNome(itemAgendamento.cliente) : ""; 
          const consultorTratado = itemAgendamento.consultor_agendado ? trataNome(itemAgendamento.consultor_agendado) : ""; 
          itemAgendamento.clienteTratado = clienteTratado;
          itemAgendamento.consultorTratado = consultorTratado;
          itemAgendamento.data = `${itemAgendamento.dataagendamento} ${itemAgendamento.horaagendada}` 
          itemAgendamento.servicosAgendadosTratado = itemAgendamento.servicosAgendados.filter(servico => servico.codTipoServico < 9).map(servico => {
            const eventConfig = config.events[servico.codTipoServico - 1];
            const start = moment(servico.dataInicioServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
            const end = moment(servico.dataFinalServico.replace(" ", "T"), "YYYY-MM-DD HH:mm:ss");
            const duration = moment.duration(end.diff(start)).asMinutes();

            let bgColor = "";
            let headerTextColor = "#fff";
            if(store.getters.modeloAgendaName == "CampV1"){
              const defaultCor = store.getters.coresAgenda[store.getters.consultoresList?.length] ?? '#d3d3d3';
              bgColor = store.getters.consultoresList.find(consultor => removeAcentos(consultor.intConsultor, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
              if(!bgColor){
                bgColor = store.getters.consultoresList.find(consultor => removeAcentos(consultor.nome, true) == removeAcentos(servico.consultorAgendado, true))?.corAgenda;
              }
              bgColor = bgColor ?? defaultCor;
            } else {
              bgColor = eventConfig.extendedProps.bgColor,
              headerTextColor = eventConfig.extendedProps.headerTextColor
            }

            const newEvent = {
              id: servico.codServico,
              resourceId: `${servico.codTecnico.toString()}`,
              title: eventConfig.title,
              start: start.format("YYYY-MM-DD HH:mm:ss"),
              end: end.format("YYYY-MM-DD HH:mm:ss"),
              extendedProps: {
                duration,
                status: servico.status,
                showDuration: eventConfig.extendedProps.showDuration,
                aguarda: servico.aguarda == "1" ? true : false,
                hasContent: eventConfig.extendedProps.hasContent,
                hasFooter: eventConfig.extendedProps.hasFooter,
                cliente: trataNome(servico.cliente),
                consultor: trataNome(servico.consultorAgendado),
                recepcao: servico.dataRecepcao ? servico.dataRecepcao.replace(" ", "T") : "", 
                entrega: servico.dataEntrega ? servico.dataEntrega.replace(" ", "T") : "",
                placa: servico.placa,
                bgColor,
                headerTextColor,
                allInfo: servico,
              }
            };

            return newEvent;
          })
        });

        // Ordena agendamentos de acordo com o horário
        baseAgendamentosList.sort((a, b) => {
          const hourA = moment(a.horaagendada, 'hh:mm');
          const hourB = moment(b.horaagendada, 'hh:mm');

          return hourA == hourB ? 0 : hourA.isBefore(hourB) ? -1 : 1; 
        });

        agendamentos.value = baseAgendamentosList.filter(agendamento => agendamento.idagendamento || agendamento.placa).filter(agendamento => agendamento.servicosAgendados.length == 0);
        agendamentosAgendados.value = baseAgendamentosList.filter(agendamento => agendamento.idagendamento || agendamento.placa).filter(agendamento => agendamento.servicosAgendados.length > 0);
        loading.value = false;
      } catch (e){
        console.error(e);
        showTimeAlert('Não foi possível completar a solicitação', "error");
      } finally {
        loading.value = false;
      }
    }

    async function openModalSericoPassante(){
      initialForm.value = await {
        codAgendamento: "",
        detalhes: "",
        taskType: "",
        cliente: "",
        placa: "",
        chassi: "",
        taskHourEntrega: "",
        taskHourRecepcao: "",
        formType: "New",
        taskEndHour: "",
        taskDateTratada:  moment(lastDateFilter.value , "YYYY-MM-DD HH:mm:ss"),
        taskDate:  moment(lastDateFilter.value , "YYYY-MM-DD HH:mm:ss"),
        taskStartHour: horaAbertura.value,
        cnpjConcessionaria: concessionariaInfo.value.cnpj,
        codConcessionaria: concessionariaInfo.value.codConcessionaria,
        dataAgendamento: moment().format("YYYY-MM-DD HH:mm"),
        marca: concessionariaInfo.value.marca,
        modelo: "",
        consultorAgendado: "",
        osAberta: ""
      }
      
      refModalNewWithoutRef.value.show();
    }

    function newTask(agendamento) {
      initialForm.value = {
        codAgendamento: agendamento.idagendamento,
        detalhes: agendamento.obs,
        taskType: "",
        cliente: agendamento.cliente,
        placa: agendamento.placa,
        chassi: agendamento.chassi,
        taskHourEntrega: "",
        taskHourRecepcao: agendamento.horaagendada,
        formType: "New",
        taskEndHour: "",
        taskDateTratada: agendamento.dataagendamento,
        taskDate: moment(agendamento.dataagendamento, "DD/MM/YYYY"),
        taskStartHour: agendamento.horaagendada,
        cnpjConcessionaria: concessionariaInfo.value.cnpj,
        codConcessionaria: concessionariaInfo.value.codConcessionaria,
        dataAgendamento: `${agendamento.dataagendamento} ${agendamento.horaagendada}`,
        marca: agendamento.marca,
        modelo: agendamento.modelo,
        consultorAgendado: agendamento.consultorcodigo ?? agendamento.consultor_agendado,
        consultorAgendadoTratado: agendamento.consultor_agendado,
        osAberta: agendamento.osaberta,
        solicitacao: agendamento.solicitacao ?? []
      }
      
      refModalNew.value.show();
    };

    return { 
      closeModalServicosAgendados, 
      openEditVisualizarServicoModal, 
      openModalServicosAgendados, 
      initialForm, 
      eventInfo, 
      openVisualizarServicoModal, 
      moment, 
      refModalNew, 
      agendamentos, 
      agendamentosAgendados, 
      newTask, 
      emiteSuccess, 
      filtraDados, 
      dataFiltrada, 
      openModalSericoPassante,
      loading,
      cutChassi
    };
  },
});
